<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      ref="my-modal"
      centered
      class="rounded-0"
      size="md"
      title="Adres Tanımla"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-card-text class="p-1">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Başlık">
              <b-form-input v-model="form.baslik" class="rounded-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Dosya Url">
              <b-input-group>
                <b-input-group-append is-text>
                  <a :href="form.url" target="_blank">
                    <i :class="`fad fa-eye`" />
                  </a>
                </b-input-group-append>
                <b-form-input
                  size="lg"
                  ref="urlData"
                  class="rounded-0"
                  readonly
                  v-model="form.url"
                />
                <b-input-group-prepend is-text>
                  <i
                    :class="`fad fa-copy`"
                    style="cursor: pointer"
                    @click="handlerCopy(form.url)"
                  />
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <b-button
            squared
            variant="primary"
            class="float-right"
            type="submit"
            @click="ok()"
            :disabled="show"
          >
            <i class="fad fa-save" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            {{ form.k_no ? "Güncelle" : "Kaydet" }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  ref,
  watch,
  computed,
  defineComponent,
  toRefs,
} from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
    },
    updateData: {
      type: Object,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { openModal, updateData } = toRefs(props);
    expo.show = ref(false);
    expo.form = ref({});

    expo.firma = computed(() => store.getters.getUserFirma);

    expo.showModal = () => {
      context.refs["my-modal"].show();
    };
    expo.hideModal = () => {
      context.refs["my-modal"].hide();
      context.emit("closeModal", false);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      store.dispatch("medyaOrtamDosyaGuncelle", expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success("Güncelleme Başarılı", { position: "bottom-left" });
          expo.hideModal();
        }
      });
    };

    expo.handlerCopy = async (event) => {
      await navigator.clipboard.writeText(event);
      toast.success("Url Kopyalandı.", { position: "bottom-left" });
    };

    watch(
      openModal,
      (val) => {
        if (val == true) {
          expo.showModal();
          if (Object.keys(updateData.value).length) {
            const { VUE_APP_PRO_BASEURL } = process.env;
            const url = `${VUE_APP_PRO_BASEURL}/uploads/${expo.firma.value.db}/medya/${updateData.value.dosya_adi}`;
            expo.form.value = { ...updateData.value, url };
          }
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
