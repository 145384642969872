<template>
  <div>
    <b-row>
      <b-col>
        <div>
          <input
            multiple
            type="file"
            ref="file"
            name="logo"
            id="selectorImagesLogoInput"
            accept="image/jpg,image/jpeg,image/png,image/gif,application/pdf, application/msexcel,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            class="selector-images-input"
            @change="handlerOnSumbited"
          />
          <div
            class="selector-images-button"
            :disabled="true"
            @click="handlerSelectedLogoImages"
          >
            <i class="fad fa-file-plus fa-3x" v-if="!show" />
            <b-spinner v-else />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="!dosyalar.length">
      <b-col>
        <b-alert variant="warning" show class="text-center">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Henüz Kayıt Bulunmamaktadır.</p>
          <p>
            Çoklu Dosya yüklemeler de bazı gorseller görünmeyebilir sayfayı
            yenileyiniz lütfen.
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <draggable
      v-else
      v-model="dosyalar"
      tag="div"
      class="row"
      handle=".draggable-task-handle"
      @end="handlerGorselSiraChange"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        sm="6"
        v-for="(item, index) in dosyalar"
        :key="index"
      >
        <div class="images-show">
          <div class="images-action">
            <span>
              <i
                class="fad fa-trash"
                v-b-tooltip.hover.top="'Sil'"
                @click="handlerRemove(item)"
              />
              <i
                class="fad fa-edit pl-2"
                v-b-tooltip.hover.top="'Düzenle'"
                @click="handlerUpdate(item)"
              />
            </span>
          </div>
          <b-img-lazy
            v-bind="mainProps"
            class="draggable-task-handle m-1"
            :src="handlerImageShow(item)"
          />
          <div class="images-footer">
            {{ item.baslik }}
          </div>
        </div>
      </b-col>
    </draggable>
    <MedyaOrtamItemModal
      :openModal="openModal"
      @closeModal="openModal = $event"
      :updateData="updateData"
    />
  </div>
</template>

<script>
import store from "@/store";
import draggable from "vuedraggable";
import {
  defineComponent,
  computed,
  ref,
  toRefs,
  watch,
} from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import { useRouter } from "@/libs/utils";
import MedyaOrtamItemModal from "./MedyaOrtamItemModal.vue";
export default defineComponent({
  components: {
    draggable,
    MedyaOrtamItemModal,
  },

  setup(props, context) {
    const excelLogo = require("@/assets/images/logo/excel-logo.jpg");
    const wordLogo = require("@/assets/images/logo/word-logo.jpg");
    const pdfLogo = require("@/assets/images/logo/pdf-logo.jpg");
    const noImg = require("@/assets/images/logo/no-img.jpg");
    const expo = {};

    const { route } = useRouter();
    const toast = useToast();

    expo.openModal = ref(false);
    expo.updateData = ref({});
    expo.show = ref(false);
    expo.dosyalar = computed(() => store.getters.getMedyaOrtamDosyalar);

    expo.mainProps = ref({
      center: true,
      fluidGrow: true,
      blank: true,
      blankColor: "#bbb",
      thumbnail: true,
      show: true,
    });

    const handlerFetchAllData = () => {
      const k_no = route.value.params.k_no;
      if (k_no) {
        store.dispatch("medyaOrtamDosyaListele", { k_no });
      }
    };

    handlerFetchAllData();

    expo.handlerImageShow = (val) => {
      if (val != undefined) {
        if (
          val.format == "jpg" ||
          val.format == "jpeg" ||
          val.format == "png" ||
          val.format == "gif"
        ) {
          const fileName = store.getters.getUserFirma.db;
          const base_url =
            process.env.NODE_ENV === "development"
              ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
              : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

          return `${base_url}uploads/${fileName}/medya/${val.dosya_adi}`;
        } else if (val.format == "pdf") {
          return pdfLogo;
        } else if (val.format == "doc" || val.format == "docx") {
          return wordLogo;
        } else if (val.format == "xls" || val.format == "xlsx") {
          return excelLogo;
        } else {
          return noImg;
        }
      }
    };

    expo.handlerSelectedLogoImages = () => {
      const file = document.getElementById("selectorImagesLogoInput");
      file.click();
    };

    expo.handlerOnSumbited = async () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = context.refs.file.files;
      Object.keys(forms).map(function (key) {
        formData.append("medya", forms[key]);
      });

      formData.append("medya_k_no", route.value.params.k_no);

      await store
        .dispatch("medyaOrtamDosyaEkle", formData)
        .then(async (res) => {
          expo.show.value = false;
          context.emit("form", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
        });
    };

    expo.handlerRemove = (item) => {
      store.dispatch("medyaOrtamDosyaSil", item).then((res) => {
        if (res.data.success == true) {
          toast.error("Dosya Silme Başarılı.", { position: "bottom-left" });
        }
      });
    };

    expo.handlerGorselSiraChange = () => {
      store
        .dispatch("medyaOrtamDosyaSiraGuncelle", {
          k_no: form.value.k_no,
          dosyalar: expo.dosyalar.value,
        })
        .then((res) => {
          if (res.data.success == true) {
            toast.success("Dosya sıra güncelleme başarılı.", {
              position: "bottom-left",
            });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0
                ? Object.keys(err.data.dup)[0] + " " + err.data.message
                : err.data.message,
              {
                position: "bottom-left",
              }
            );
          }
        });
    };

    expo.handlerUpdate = (payload) => {
      expo.openModal.value = true;
      expo.updateData.value = payload;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.images-show {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  cursor: move;
  .images-action {
    position: absolute;
    right: 0px;
    top: 20px;
    span {
      opacity: 0;
      visibility: hidden;
      box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      background: #fff;
      padding: 5px;
      margin-right: 10px;
      cursor: pointer;
      .fa-trash {
        color: red;
      }

      &.active {
        opacity: 1;
        visibility: visible;
        color: green;
      }
    }
  }

  img {
    width: 100%;
    height: 150px;
    object-fit: scale-down;
    &.img-thumbnail {
      background: #fff;
      overflow: hidden;
    }
  }

  .images-footer {
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    height: 40px;
    background: #71717183;
    text-align: center;
    color: #ffffff;
  }

  &:hover {
    .images-action {
      span {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>
